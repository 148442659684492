import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
const ChatList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userid, SetUserID] = useState()
  const userT = localStorage.getItem("userType")
  const navigate = useNavigate()
  useEffect(() => {
    const userID = localStorage.getItem("userid")
    SetUserID(userID);
    const userType = localStorage.getItem("userType")
    // console.log(userID)
    const fetchUsers = async () => {
      try {
        if (userType === "student") {
          const url = process.env.REACT_APP_API_URL + "chat/expertlist/" + userID;
          // console.log(url)
          const response = await axios.get(url);
          // console.log(users)
          setUsers(response.data);
          // console.log("Users",response.data)
        }
        else if (userType === "expert" || userType === "admin") {
          const url = process.env.REACT_APP_API_URL + "chat/userlist/" + userID;
          const response = await axios.get(url);
          setUsers(response.data);
          console.log("chatlist for expert", response.data)
        }
        else {
          setError("You Are not Authorize to access chats")
        }

      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();

  }, [1])
  const handleClickByStudent = (expertid) => {
    navigate(`/chat/${expertid}/${userid}`)
  }
  const handleClickByExpert = (expertid) => {
    navigate(`/chat/${expertid}/${userid}`)
  }
  return (
    <div class="col-xl-4 col-lg-4">
      <div class="tp-faq-inner__tab-btn">
        <nav>
          <div className="nav nav-tab" id="nav-tab" role="tablist">
            {
              userT === `student` && <><button className="nav-links active" id="nav-1-tab" >Chats<i lass="fa-light fa-arrow-up"></i></button>
                {users.length > 0 ? (users.map((user) => (

                  <button key={user.expertid} onClick={() => handleClickByStudent(user.expertid)} className="nav-links" id="nav-4-tab">
                    {user.expertid}
                  </button>

                ))) : (<p>No any chat available</p>)}</>
            }
            {
              userT === `expert` && <><button className="nav-links active" id="nav-1-tab" >Chats<i lass="fa-light fa-arrow-up"></i></button>
                {users.length > 0 ? (users.map((user) => (

                  <button key={user.userid} onClick={() => handleClickByExpert(user.userid)} className="nav-links" id="nav-4-tab">
                    {user.userid}
                  </button>

                ))) : (<p>No any chat available</p>)}</>
            }
          </div>
        </nav>
      </div>
      {error && <p>{error}</p>}
    </div>
  )
}

export default ChatList
