import './App.css';
import './style.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './css/style.css';
import DataApp from './DataContext';

import Header from './components/shared/Header';
import Footer from './components/shared/Footer';
import Footer_Light from './components/shared/Footer_Light';
import Home from './components/Home';
import Product from './components/Product';
import Category from './components/Category';

import About from './components/About';
import Courses from './components/Courses';
import Admission from './components/Admission';
import Contact from './components/Contact';
import Experts from './components/Experts';
import ExpertDetail from './components/ExpertDetail';
import FAQ from './components/FAQ';

import Dashboard from './components/Dashboard';

import ExpertsAdmin from './components/secure/ExpertsAdmin';
import ExpertsAdminAdd from './components/secure/ExpertsAdminAdd';
import ExpertsAdminEdit from './components/secure/ExpertsAdminEdit';

import UsersAdmin from './components/secure/UsersAdmin';

import ExpertsAdminView from './components/secure/ExpertsAdminView';

import Login from './components/Login';
import Register from './components/Register';
import Chat from './components/Chat';
import UserView from './components/secure/UserView';
import UserAdd from './components/secure/UserAdd';
import UserUpdate from './components/secure/UserUpdate';




function App() {
 
  return (
    <>
      <BrowserRouter>
        <DataApp>
          <Header />
         
          <Routes>
            <Route path='/chat' element={<Chat />} />
            <Route path='/home' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/courses' element={<Courses />} />
            <Route path='/admission' element={<Admission />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/experts' element={<Experts />} />
            <Route path='/expertdetail/:expertid' element={<ExpertDetail />} />
            <Route path='/faq' element={<FAQ />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/login' element={<Login/>} />
            <Route path='/register' element={<Register/>} />
            <Route path='/dashboard_experts' element={<ExpertsAdmin />} />
            <Route path='/dashboard_experts/viewExpert/:expertid' element={<ExpertsAdminView />} />
            <Route path='/dashboard_experts/addExpert' element={<ExpertsAdminAdd />} />
            <Route path='/dashboard_experts/editExpert/:expertid' element={<ExpertsAdminEdit />} />
            <Route path='/dashboard_users' element={<UsersAdmin />} />
            <Route path='/chat/:expertid/:userid' element={<Chat/>} />
           
            {/* <Route path='/cart' element={<Home />} />
            <Route path='/checkout' element={<Home />} />
            <Route path='/ordersuccess' element={<Home />} />
            <Route path='/category/:categoryid' element={<Category />} />
            <Route path='/subcategory/:subcategoryid' element={<Home />} />
            <Route path='/product/:productid' element={<Product />} /> */}
             <Route path='/dashboard_users' element={<UsersAdmin/>} />
            <Route path='/dashboard_users/userview/:userid' element={<UserView/>}/>
            <Route path='/dashboard_users/useradd' element={<UserAdd/>}/>
            <Route path="/dashboard_users/userupdate/:userid" element={<UserUpdate/>} />
            <Route path='/' element={<Home />} />
          </Routes>
          {/* <Home /> */}
          <Footer_Light />
        </DataApp>

      </BrowserRouter>
    </>
  );
}

export default App;
